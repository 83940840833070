.section__proceso{
    text-align: center;
    padding: 2rem;
}

.text__paragraph{
    white-space: pre-line;
}

.text__secondary{
    color: var(--second-color);
}

.proceso__title{
    padding: 1rem 0;
}

.proceso__container{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.proceso__imagen{
    max-width: 90vw;
}

.timeline__container{
    position: relative;
    text-align: start;
}

.timeline__container h5{
    font-weight: 600;
    padding-right: 3rem;
    height: 100%;
}

.timeline__titulo{
    min-height: 63px;
    margin-bottom: 3rem;
}

.timeline__container p{
    font-size: 0.75rem;
    padding-right: 3rem;
    text-align: justify;
    color: grey;
}

.timeline__arrow::after{
    position: absolute;
    content: '';
    top: 3.5rem;
    height: 100%;
    width: 99%;
    z-index: 2;
    background: url(../../../../media/img/arrow_right.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.text-transparent{
    color: transparent;
}

@media screen and (min-width:1440px){
    .timeline__container p{
        font-size: 1rem;
        padding-right: 4rem;
    }
}

@media screen and (max-width:1281px){    
    .timeline__titulo{
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width:1025px){
    .timeline__arrow::after{
        top: 4.5rem;
    }
    
    .timeline__container h5{
        font-size: 1rem;
    }

}

@media screen and (max-width:732px){
    .timeline__container{
        padding-left: 1.5rem;
    }

    .timeline__arrow::after{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 5%;
        background: url(../../../../media/img/arrow_down.png);
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 2;    
    }
}

@media screen and (max-width:361px){
    .section__proceso{
        padding: 1rem;
    }

    .timeline__container{
        margin: 1rem!important;
    }

    .timeline__titulo{
        min-height: 50px;
        margin-bottom: 0;
    }
}