.section__modelos{
    text-align: center;
    padding: 1rem;
    padding-top: 50px;
}

.text__paragraph{
    white-space: pre-line;
}

.text__secondary{
    color: var(--second-color);
}

.modelos__title{
    padding: 1rem 0;
}

.modelos__container{
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.row>div, .row>div>div{
    padding: 0;
}

.card__container__modelos{
    position: relative;
    text-align: start;
    height: 14rem;
    max-width: 650px;
}

.card__container__modelos>div{
    height: 100%;
}

.card__imagen__modelo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card__container__modelos:hover{
    cursor: pointer;
    opacity: 0.7;
}

.card__titulo__modelo{    
    color: #DED;
    font-weight: 700;    
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-shadow: 2px 2px 12px rgba(100,100,100,0.5);
}

.card__caracteristica__modelo{
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
    margin-bottom: 0.25rem;    
}

.card__caracteristica__modelo li{
    font-size: 14px!important;
    text-align: center!important;
}

.card__overlay__modelo {
    position: absolute;
    bottom: 0;
    /* background: rgba(50, 70, 150, 0.6); */
    /* background: rgba(200, 200, 200, 0.7);     */
    /* background: linear-gradient(to right, rgba(200,200,200,0.5), rgba(200,200,200,0.7)); */
    background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.2));
    backdrop-filter: blur(2px);
    color: #f1f1f1;
    width: 100%;
    height: 6rem;
    text-align: start;
    transition: .5s ease;
    opacity:1;
    color: var(--first-color);
    font-size: 20px;
    padding: 0.5rem 0;    
    text-align: center;
    z-index: 2;
    overflow-y: scroll;
}

.card__overlay__modelo::-webkit-scrollbar {
    display: none;
}

.card__overlay__modelo ul{
    min-height: 50%;
}

.card__overlay__modelo li{
    color: #FFF;
    text-align: start;
    list-style: none;
    font-size: 0.8rem;
    margin: 0;
    padding: 0 1rem;
}

@media screen and (max-width: 1280px){
    .modelos__container{     
        padding: 0;
    }

    .card__overlay__modelo {
        height: 5rem;
    }

    .card__caracteristica__modelo li{
        font-size: 12px!important;
    }
}

@media screen and (max-width: 1024px){
    .card__container__modelos{
        height: 14rem;
    }

    .card__imagen__modelo{        
        object-fit: cover;
        object-position: center;
    }
}