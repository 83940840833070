.spacer{
    flex: 1;
}

.div__home{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fadein 2.5s;
}
