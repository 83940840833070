.blog__container{
    padding: 4rem!important;
    white-space: pre-line;
    max-width: 1000px;
}

.blog__subtitulo{
    font-size: 1.2rem;
}

.blog__descripcion{
    text-align: justify;
    max-width: 1000px;
    color: grey;
}

.blog__descripcion a{
    font-size: 1rem; 
    color: #4b503f!important;
}

.blog__descripcion a:hover{
    opacity: 0.7;
}

.blog__descripcion strong, .blog__descripcion h4{
    color: #000;
}

.imagen__blog{
    max-width: 1000px;
    object-fit: contain;
}

@media screen and (max-width: 479px){
    .blog__container{
        padding: 1rem!important;
    }
}