.section__proyectos{
    text-align: center;
    padding: 1rem;
    padding-top: 100px;
}

.text__paragraph{
    white-space: pre-line;
}

.text__secondary{
    color: var(--second-color);
}

.proyectos__title{
    padding: 1rem 0;
}

.proyectos__container{
    display: flex;
    justify-content: center;
    padding: 0!important;
}

.row>div, .row>div>div{
    padding: 0;
}

.card__container__proyectos{
    position: relative;
    text-align: start;
    max-height: 230px;
}

.card__imagen__proyecto{
    width: 100%;
    max-height: 230px;
    object-fit: cover;
}

.card__container__proyectos:hover{
    cursor: pointer;
    opacity: 0.7;
}

.card__container__proyectos:hover .card__overlay__proyecto{
    display: flex;
}

.card__titulo__proyecto{
    color: var(--background-color);
    font-weight: bold;
    margin-bottom: 1rem;
}

.card__overlay__proyecto h5, .card__overlay__proyecto h6{
    margin-top: 1rem!important;
}

.card__overlay__proyecto {
    position: absolute;
    bottom: 0;
    background: rgba(50, 70, 150, 0.6); /* Black see-through */    
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    text-align: start;
    transition: .5s ease;
    opacity:1;
    color: white;
    font-size: 20px;
    padding: 0.25rem;
    text-align: center;
    display: none;
    z-index: 3;
}


.card__overlay__proyecto ul{
    min-height: 50%;
}

.card__overlay__proyecto li{
    text-align: start;
    list-style: none;
    font-size: 0.75rem;
}

.proyectos__container .owl-stage-outer{
    z-index: 0;
}

.proyectos__container .owl-nav{
    z-index: 0;
    height: 1px;    
    top: 35%;
}

.proyectos__container .owl-prev, 
.proyectos__container .owl-next{
    z-index: 100;
}

.owl-nav{
    position: absolute;
    top: 45%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.owl-nav span{
    color: white;
    padding: 0 10px 6px 10px;
    margin: 0 0.5rem;
    font-size: 2rem;
    background-color: rgba(0,0,0,0.8);
}

.modal__proyecto{
    min-width: 65vw;
}

.modal__proyecto .modal-dialog{
    height: 100vh;
    min-width: 65vw;
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1100px){
    .card__container__proyectos, .card__imagen__proyecto{
        max-height: 195px;
    }
}


@media screen and (max-width: 900px){
    .card__container__proyectos, .card__imagen__proyecto{
        max-height: 180px;
    }
}