.instagram__header{
    max-width: 1000px;
    width: 100%;
    align-items: start;
    padding: 2rem 0;
}

.instagram__title{    
    text-align: start;
    padding: 1rem 0;
}

.instagram__logo{
    margin: 2rem 0;
    border-radius: 50%;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    width: 6rem;
}

.instagram__link:hover{
    cursor: pointer;
    opacity: 0.7;
}

.instagram__container {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
}

.instagram__card {
    padding: 1rem;
    width: 100%;
    object-fit: contain;        
    max-height: 500px;
}

.instagram__card video::-webkit-media-controls {
    display:block !important;
}

.instagram__card:hover{
    opacity: 0.5;
}

video.instagram__card{
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1025px) {
    .instagram__header{
        padding: 2rem 1rem;
    }
}

@media (max-width: 769px) {
    .instagram__container{
        grid-template-columns: repeat(2, 1fr);
    }

    .instagram__header{
        padding: 2rem 4rem;
    }
    
    #instagramPost_4, #instagramPost_5{
        display: none;
    }
}

@media (max-width: 600px) {
    .instagram__container{
        grid-template-columns: repeat(1, 1fr); 
    }
    
    .instagram__header{
        padding: 2rem;
    }

    .instagram__card {        
        max-height: 500px;
    }
}

@media (max-width: 400px) {
    
    .instagram__card {        
        max-height: 500px;
        padding: 0;
    }
}
