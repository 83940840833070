.section__blogs{
    text-align: center;
    padding: 1rem;
}

.section__blogs .text__paragraph{ 
    white-space: pre-line;
}

.section__blogs .text__secondary{
    color: var(--fifth-color);
}

.section__blogs .blog__title{
    padding: 1rem 0;
}

.blog__container{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.blog__imagen{
    object-fit: contain;
}