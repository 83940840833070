.img__proyecto__container, .proyecto__container{
    padding: 2rem!important;
    margin-top: 50px;
    background-color: #000;
}

.proyecto__carousel__img{
    object-fit: cover;
    max-height: 80vh;
    background-color: #000;
}

.list__caracteristicas__proyecto li{
    margin-bottom: 0;
}

.button__close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    z-index: 100;
}

.proyectos__container .owl-item{
   
    background-color: #000;
}

.proyectos__container .owl-stage{
    background-color: rgba(0,0,0,0.8);
}


.proyectos__container  .owl-stage {
    display: flex;
}

.proyectos__container  .owl-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card__imagen__proyecto{
    object-fit: cover!important;
}

.proyectos__carousel__modal .owl-nav{
    top: 45%;
}

.modal__body__proyecto{
    margin-top: -60px;
}

@media screen and (max-width: 992px){
    .proyecto__container{
        margin: 0;
    }
}


@media screen and (max-width: 769px){
    .proyecto__carousel__img{
        object-fit: cover;
        max-height: 40vh;
    }
}