.section__nosotros{
    text-align: center;
    padding: 1rem;
}

.section__nosotros .text__paragraph{
    white-space: pre-line;
}

.section__nosotros .text__secondary{
    color: var(--second-color);
}

.nosotros__title{
    padding: 1rem 0;
}

.nosotros__imagen{
    object-fit: contain;
}

.img__nosotros{
    object-fit: cover;
    object-position: center;
    width: 50%;
    height: 70%;
    max-width: 600px;
}

.div__nosotros p{
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin: 0;
}