.section__inversiones{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    padding: 100px 0;
    background: linear-gradient(0deg, #EEE 50%, #000 50%);
}

.text__paragraph{
    white-space: pre-line;
}

.text__secondary{
    color: var(--second-color);
}

.inversiones__title{
    padding: 1rem 0;
}

.inversiones__container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.card__container{
    min-height: 20rem;
    width: 18rem;
    max-width: 90vw;
    background-color: #FFF;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
}

.card__titulo__inversion{
    color: var(--first-color);
    padding: 1rem 0;
}

.card__caracteristicas{
    flex-wrap: wrap;
}

.card__imagen__inversion{
    width: 100%;
    max-height: 155px;
    object-fit: cover;
}

.card__descripcion{
    padding: 1rem;
    text-align: justify;
    color: grey;
}

.tag__container{
    color: var(--fifth-color);;
    border: 1px solid var(--fifth-color);
    border-radius: 1rem;
    padding: 0.25rem;
    margin: 5px;
    display: flex;
    align-items: center;
}

.tag__text{
    margin: 0;
    font-size: 0.7rem;
}

@media screen and (max-width: 1919px){
    .inversiones__container{
        max-width: 1200px;
    }

    .tag__container{        
        padding: 0.3rem;
    }

    .tag__text{
        margin: 0;
        font-size: 11px;
    }
}

@media screen and (max-width: 1280px){
    .card__descripcion{
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 768px){
    .card__container{     
        width: 20rem;
    }
}


@media screen and (max-width: 479px){
    .card__descripcion{
        display: none;
    }
}