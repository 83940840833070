.section__beneficios{
    text-align: center;
    padding: 2rem;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
}

.text__paragraph{
    white-space: pre-line;
}

.text__secondary{
    color: var(--second-color);
}

.beneficios__title{
    padding: 1rem 0;
}

.beneficios__container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 1920px;
}

.card__container__beneficios{
    padding: 1rem;
    min-height: 15rem;
    width: 30rem;
    max-width: 100vw;
    text-align: start;
}

.card__imagen__beneficio{
    filter: var(--filter-grey-color);
}

.card__titulo__beneficio{
    letter-spacing: 1cap;    
}

.card__descripcion__beneficios{
    color: grey;
    text-align: justify;
    padding-left: 0;
}

@media screen and (max-width: 1280px){
    .card__container__beneficios{
        width: 20rem;
    }

    .card__descripcion__beneficios{
        font-size: 0.75rem;
    }
}