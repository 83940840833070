.about [class*="col-"] {
    margin-bottom: 10px; 
    display: flex;   
    flex-direction: column;
    justify-content: start;
}

.sectionTitle {
    width: 100%;
    color: var(--title-light-color);
    margin-bottom: 25px;   
    font-family: var(--font-primary)!important;    
}

.sectionTitle span {
    display: block;
    color: var(--first-color)!important;
    margin-bottom: 20px;
    position: relative;
    z-index: 1; 
}

.sectionTitle h2 {
    color: var(--fifth-color);
    margin-bottom: 0.5rem;
    margin-left: -0.25rem;
}

.title, .subtitle{
    width: 100%;
    text-align: start;
    margin-bottom: 2rem;
}

.section__descripcion{
    white-space: pre-line;
    text-wrap: wrap;
    text-align: start;
    color: grey;
}

.about p {
    margin-bottom: 40px;
    white-space: pre-line;
    text-wrap: wrap;
    padding-right: 1rem;
}

.options {
    display: flex;
    align-items: center;
}

.optionsList {
    padding-left: 30px;
}

.options .block {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
}

.options .block a {
    margin-bottom: 0;
    color: var(--span-color);
    font-family: var(--font-secondary);
    text-decoration: underline;
}

.rightContent {
    display: block;
    overflow: hidden;
    padding-left: 20px;
}

.options .block:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.imagen{
    min-height: 500px;
    width: auto;
    object-fit:contain;
    object-position: top;
}

.section__descripcion{
   font-size: 14px;
   text-align: justify;
   padding-right: 2rem!important;
   white-space: pre-wrap;
}  

@media screen and (max-width: 1399px){    
    .imagen{
        min-height: 400px;
    }
}

@media screen and (max-width: 991px){
    .aboutImage {
        display: inline-block;
        margin-bottom: 100px;
    }

    .about{
        padding: 50px 0;
    }

    .imgContainer {
        text-align: center;
    }
}
@media screen and (max-width: 479px){     
    .about{
        padding: 25px 0;
    }

    .sectionTitle span {
        margin-bottom: 15px;
    }

    .sectionTitle h2 {
        padding-top: 1rem;
    }

    .options {
        display: block;
    }

    .options img {
        width: 100%;
    }

    .optionsList {
        padding: 30px 0 0;
    }

    .optionsList .block {
        display: flex;
        align-items: center;
        padding-bottom: 18px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
    }

    .options .optionsList img {
        width: auto;
    }

    .options button{
        padding: 1rem;
    }
        
    .imagen{
        min-height: 200px;
    }
}

@media screen and (max-width: 769px){    
    .section__descripcion{
        padding-right: 0!important;
    }
}

@media screen and (max-width: 399px){ 
    .aboutImage {
        max-width: 220px;
    }
}

@media screen and (min-width: 1281px){ 
    .section__descripcion{
        font-size: 1rem;
    }    
}
