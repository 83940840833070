.container-fluid{
    max-width: 1400px;
}

.header__logo{
    filter: drop-shadow(1px 1px 1px #000);
    border: 0;
}

.header__logo:focus{
    box-shadow: 0 0 0 transparent;
}

a.navbar-toggler{
    height: 50px;
}

.navbar-nav{
    width: 100%;
    justify-content: space-around;
}

.navbar-nav li{
    min-height: 3rem;
}

.navbar-brand{
    margin-right: 0!important;
}

.navbar a:hover, button:hover{
    opacity: 0.7;
}

.nav-item{
    align-items: center;
}

.nav-link{
    font-size: 16px;
    color: #FFF;
    padding: 0 1rem!important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.7);
}

.nav-link:hover{
    color: var(--fifth-color);
}

.nav-link:focus{
    color: var(--fifth-color);
}

.navbar__bg{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: var(--first-color);
    padding: 0 3rem;  
    min-height: 50px;
}

@media screen and (max-width: 1280px){ 
    .navbar__bg{
        padding: 0;    
    }
}
/* 
@media screen and (max-width: 1200px){
    .navbar__bg{
        background-color: var(--first-color)!important;
    }
} */
