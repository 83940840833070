.modelo__container h4{
    font-weight: 600;
}

.img__modelo__container, .modelo__container{
    padding: 2rem!important;
    margin-top: 50px;
}

.img__modelo__container img{
    max-height: 25rem;
    object-fit: cover;
}

.list__caracteristicas__modelo li, .modelo__container p{
    margin-bottom: 0;
    font-size: 14px;
}

.modelos__container  .owl-stage {
    display: flex;
}

.modelos__container  .owl-item img {
    width: 100%;
    min-width: 100%;
    height: 100%;
    object-fit: contain;
    backdrop-filter: blur(2px);
}

.img__cover{
    object-fit: cover!important;
}

.img__fill{
    object-fit: fill!important;
}

.wide__containe, .wide__container .owl-item img {
    height: auto;
    max-height: 18rem!important;
    height: 18rem!important;
}


@media screen and (max-width: 992px){
    .modelo__container{
        margin: 0;
    }
}


@media screen and (max-width: 600px){
    .img__modelo__container img{
        max-height: 12rem;
        object-fit: cover;
    }
}